import React from "react";

const UnderConstruction = () => {
    return (
        <div style={{ textAlign: "center", paddingTop: "100px" }}>
            <h1>Page under construction</h1>
        </div>
    );
};

export default UnderConstruction;
